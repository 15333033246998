// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { DefaultPage, TablesPage, FormsPage } from './';
// import { Layout } from './';

export default {
  path: 'fiori',
  name: 'Fiori3',
  // component: Layout,
  childRoutes: [
    { path: '', name: 'Fiori3 Page', component: DefaultPage },
    { path: 'tables', name: 'Fiori3 Tables', component: TablesPage },
    { path: 'forms', name: 'Fiori3 Forms', component: FormsPage },
  ],
};
