import React from 'react';
// import React, { useState } from 'react';

const Spinner = ({ cssClass = '' }) => {
  // <div className="common-spinner">
  //       <p>You clicked {count} times</p>
  //       <button onClick={() => setCount(count + 1)}>Click me</button>
  //     </div>
  return (
    <div className={'fd-spinner ' + cssClass} aria-hidden="false" aria-label="Loading">
      <div />
    </div>
  );
};
export default Spinner;
