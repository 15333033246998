import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Panel, Table, Image, Popover, Menu, Button, ListGroup } from 'fundamental-react';

export class TablesPage extends Component {
  static propTypes = {
    fiori: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="fiori-tables-page fd-has-padding-tiny">
        <Panel>
          <Panel.Header>
            <Panel.Head description="" title="Simple Table" />
            <Panel.Actions />
          </Panel.Header>
          <Panel.Body>
            <Table
              headers={['Column Header 1', 'Column Header 2', 'Column Header 3', 'Column Header 4']}
              tableData={[
                {
                  rowData: ['Data 1', 'Data 2', 'Data 3', 'Data 4'],
                },
                {
                  rowData: ['Data 5', 'Data 6', 'Data 7', 'Data 8'],
                },
              ]}
            />
          </Panel.Body>
        </Panel>
        <Panel>
          <Panel.Header>
            <Panel.Head description="" title="Rich Table" />
            <Panel.Actions />
          </Panel.Header>
          <Panel.Body>
            <Table
              headers={[
                <input type="checkbox" />,
                'Avatar',
                'email',
                'First Name',
                'Last Name',
                'Date',
                ' ',
              ]}
              tableData={[
                {
                  rowData: [
                    <input type="checkbox" />,
                    <Image photo="https://robohash.org/green?size=50x50" size="m" />,
                    <a className="fd-has-font-weight-semi" href="https://google.com">
                      user.name@email.com
                    </a>,
                    'First Name',
                    'Last Name',
                    '01/26/17',
                    <Popover
                      body={
                        <Menu>
                          <Menu.List>
                            <Menu.Item url="/">Option 1</Menu.Item>
                            <Menu.Item url="/">Option 2</Menu.Item>
                            <Menu.Item url="/">Option 3</Menu.Item>
                            <Menu.Item url="/">Option 4</Menu.Item>
                          </Menu.List>
                        </Menu>
                      }
                      control={<Button glyph="vertical-grip" option="light" />}
                      placement="bottom-end"
                    />,
                  ],
                },
                {
                  rowData: [
                    <input type="checkbox" />,
                    <Image photo="https://robohash.org/brown?size=50x50" size="m" />,
                    <a className="fd-has-font-weight-semi" href="https://google.com">
                      florence.garcia@qwerty.io
                    </a>,
                    'First Name',
                    'Last Name',
                    '07/29/18',
                    <Popover
                      body={
                        <Menu>
                          <Menu.List>
                            <Menu.Item url="/">Option 1</Menu.Item>
                            <Menu.Item url="/">Option 2</Menu.Item>
                            <Menu.Item url="/">Option 3</Menu.Item>
                            <Menu.Item url="/">Option 4</Menu.Item>
                          </Menu.List>
                        </Menu>
                      }
                      control={<Button glyph="vertical-grip" option="light" />}
                      placement="bottom-end"
                    />,
                  ],
                },
                {
                  rowData: [
                    <input type="checkbox" />,
                    <Image photo="https://robohash.org/Q27.png?set=set1&size=50x50" size="m" />,
                    <a className="fd-has-font-weight-semi" href="https://google.com">
                      mark.helper@qwerty.io
                    </a>,
                    'First Name',
                    'Last Name',
                    '05/26/18',
                    <Popover
                      body={
                        <Menu>
                          <Menu.List>
                            <Menu.Item url="/">Option 1</Menu.Item>
                            <Menu.Item url="/">Option 2</Menu.Item>
                            <Menu.Item url="/">Option 3</Menu.Item>
                            <Menu.Item url="/">Option 4</Menu.Item>
                          </Menu.List>
                        </Menu>
                      }
                      control={<Button glyph="vertical-grip" option="light" />}
                      placement="bottom-end"
                    />,
                  ],
                },
                {
                  rowData: [
                    <input type="checkbox" />,
                    <Image photo="https://robohash.org/water?&size=50x50" size="m" />,
                    <a className="fd-has-font-weight-semi" href="https://google.com">
                      user.name@email.com
                    </a>,
                    'First Name',
                    'Last Name',
                    '01/26/14',
                    <Popover
                      body={
                        <Menu>
                          <Menu.List>
                            <Menu.Item url="/">Option 1</Menu.Item>
                            <Menu.Item url="/">Option 2</Menu.Item>
                            <Menu.Item url="/">Option 3</Menu.Item>
                            <Menu.Item url="/">Option 4</Menu.Item>
                          </Menu.List>
                        </Menu>
                      }
                      control={<Button glyph="vertical-grip" option="light" />}
                      placement="bottom-end"
                    />,
                  ],
                },
              ]}
            />
          </Panel.Body>
        </Panel>
        <Panel>
          <Panel.Header>
            <Panel.Head description="" title="Simple List" />
            <Panel.Actions />
          </Panel.Header>
          <Panel.Body>
            <ListGroup>
              <ListGroup.Item>
                <a
                  style={{
                    cursor: 'pointer',
                  }}
                  href="https://www.google.com"
                >
                  List item 1
                </a>
              </ListGroup.Item>
              <ListGroup.Item>List item 2</ListGroup.Item>
              <ListGroup.Item>
                <a
                  style={{
                    cursor: 'pointer',
                  }}
                  href="https://www.google.com"
                >
                  List item 3
                </a>
              </ListGroup.Item>
              <ListGroup.Item>List item 4</ListGroup.Item>
            </ListGroup>
          </Panel.Body>
        </Panel>
        <Panel>
          <Panel.Header>
            <Panel.Head description="" title="List with Actions" />
            <Panel.Actions />
          </Panel.Header>
          <Panel.Body>
            <ListGroup>
              <ListGroup.Item>
                List item 1
                <ListGroup.ItemActions>
                  <Button glyph="edit" option="light" />
                </ListGroup.ItemActions>
              </ListGroup.Item>
              <ListGroup.Item>
                List item 2
                <ListGroup.ItemActions>
                  <Button glyph="edit" option="light" />
                </ListGroup.ItemActions>
              </ListGroup.Item>
              <ListGroup.Item>
                List item 3
                <ListGroup.ItemActions>
                  <Button glyph="edit" option="light" />
                </ListGroup.ItemActions>
              </ListGroup.Item>
              <ListGroup.Item>
                List item 4
                <ListGroup.ItemActions>
                  <Button glyph="edit" option="light" />
                </ListGroup.ItemActions>
              </ListGroup.Item>
            </ListGroup>
          </Panel.Body>
        </Panel>
        <Panel>
          <Panel.Header>
            <Panel.Head description="" title="List with Checkboxes" />
            <Panel.Actions />
          </Panel.Header>
          <Panel.Body>
            <ListGroup>
              <ListGroup.Item>
                <ListGroup.ItemCheckbox>List item 1</ListGroup.ItemCheckbox>
              </ListGroup.Item>
              <ListGroup.Item>
                <ListGroup.ItemCheckbox>List item 2</ListGroup.ItemCheckbox>
              </ListGroup.Item>
              <ListGroup.Item>
                <ListGroup.ItemCheckbox>List item 3</ListGroup.ItemCheckbox>
              </ListGroup.Item>
              <ListGroup.Item>
                <ListGroup.ItemCheckbox>List item 4</ListGroup.ItemCheckbox>
              </ListGroup.Item>
            </ListGroup>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    fiori: state.fiori,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TablesPage);
