import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
// import { Header } from '../common';
// import { SidePanel } from './';
const Header = React.lazy(() => import('./Header'));
const SidePanel = React.lazy(() => import('./SidePanel'));

export default class Layout extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <div className="fd-shell fd-shell--fundamentals">
        <div className="fd-shell__header">
          <Suspense fallback={<div>Loading...</div>}>
            <Header history={this.props.history} />
          </Suspense>
        </div>
        <div className="fd-shell__app">
          <div className="fd-app">
            <div className="fd-app__navigation fd-app__navigation--vertical">
              <Suspense fallback={<div>Loading...</div>}>
                <SidePanel />
              </Suspense>
            </div>
            <main className="fd-app__main">{this.props.children}</main>
          </div>
        </div>
        {/* <div className="fd-shell__footer"><span>SAP</span></div> */}
      </div>
    );
  }
}
