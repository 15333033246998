import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import * as actions from './redux/actions';
import {
  Alert,
  Tile,
  Identifier,
  ListGroup,
  Button,
  TileGrid,
  Image,
  ProductTile,
  Table,
  TreeView,
} from 'fundamental-react';

export class DefaultPage extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    initializing: true,
  };

  componentWillMount() {
    // this.props.actions
    //   .fetchPurchasePriceList()
    //   .then(() => {
    //     this.setState({
    //       initializing: false,
    //     });
    //   })
    //   .catch(() => {
    //     this.setState({
    //       initializing: false,
    //     });
    //   });
  }

  renderError(err) {
    return (
      <Alert dismissible link="#" linkText=" link">
        Default alert with a
      </Alert>
    );
  }

  render() {
    return (
      <div className="home-page">
        <section className="fd-section">
          <div className="fd-section__header">
            <h3 className="fd-section__title">Featured</h3>
          </div>
          <div className="fd-panel-grid fd-panel-grid--4col">
            <div className="fd-panel">
              <div className="fd-panel__body">
                <Tile
                  role="button"
                  onClick={() => {
                    this.props.history.push('/ar-invoice/list');
                  }}
                >
                  <Tile.Media>
                    <Identifier color={1} glyph="sales-order" size="m" />
                  </Tile.Media>
                  <Tile.Content title="A/R Invoices" />
                </Tile>
                <Tile
                  role="button"
                  onClick={() => {
                    this.props.history.push('/ar-invoice/list');
                  }}
                >
                  <Tile.Media>
                    <Identifier color={2} glyph="machine" size="m" />
                  </Tile.Media>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
                <Tile role="button">
                  <Tile.Media>
                    <Identifier color={3} glyph="visits" size="m" />
                  </Tile.Media>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
              </div>
            </div>
            <div className="fd-panel">
              <div className="fd-panel__body">
                <Tile>
                  <Tile.Media>
                    <Identifier color={4} glyph="task" size="m" />
                  </Tile.Media>
                  <Tile.Content title="Tile Title" />
                </Tile>
                <Tile role="button">
                  <Tile.Media>
                    <Identifier color={5} glyph="visits" size="m" />
                  </Tile.Media>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
                <Tile role="button">
                  <Tile.Media>
                    <Identifier color={6} glyph="video" size="m" />
                  </Tile.Media>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
              </div>
            </div>
            <div className="fd-panel">
              <div className="fd-panel__body">
                <Tile>
                  <Tile.Media>
                    <Image photo="https://placeimg.com/400/400/nature" size="l" type="circle" />
                  </Tile.Media>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
                <Tile>
                  <Tile.Media>
                    <Image photo="https://placeimg.com/400/400/nature" size="l" type="circle" />
                  </Tile.Media>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
                <Tile>
                  <Tile.Media>
                    <Image photo="https://placeimg.com/400/400/nature" size="l" type="circle" />
                  </Tile.Media>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
                {/* <ProductTile role="button">
                            <ProductTile.Media image="/images/team.png" />
                            <ProductTile.Content title="Tile Title">
                              <p>Tile Description</p>
                            </ProductTile.Content>
                          </ProductTile> */}
                {/* <Tile>
                            <Tile.Media>
                              <Identifier color={7} glyph="employee" size="m" />
                            </Tile.Media>
                            <Tile.Content title="Tile Title" />
                          </Tile>
                          <Tile role="button">
                            <Tile.Media>
                              <Identifier color={8} glyph="dishwasher" size="m" />
                            </Tile.Media>
                            <Tile.Content title="Tile Title">
                              <p>Tile Description</p>
                            </Tile.Content>
                          </Tile>
                          <Tile role="button">
                            <Tile.Media>
                              <Identifier color={9} glyph="chalkboard" size="m" />
                            </Tile.Media>
                            <Tile.Content title="Tile Title">
                              <p>Tile Description</p>
                            </Tile.Content>
                          </Tile> */}
              </div>
            </div>
          </div>
        </section>
        <section className="fd-section">
          <div className="fd-section__header">
            <h3 className="fd-section__title">Recent Activity</h3>
          </div>
          <div className="fd-panel-grid">
            <div className="fd-panel fd-has-grid-column-span-2">
              <TileGrid col={4}>
                <Tile colorAccent={7} rowSpan={2}>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
                <Tile>
                  <Tile.Media>
                    <Image photo="https://placeimg.com/400/400/nature" size="l" type="circle" />
                  </Tile.Media>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
                <Tile>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
                <Tile role="button">
                  <Tile.Media>
                    <Identifier color={3} glyph="home" size="l" />
                  </Tile.Media>
                  <Tile.Content title="Tile Title" />
                </Tile>
                <Tile>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
                <Tile colorAccent={4} columnSpan={2}>
                  <Tile.Content title="Tile Title">
                    <p>Tile Description</p>
                  </Tile.Content>
                </Tile>
              </TileGrid>
            </div>
            <div className="fd-panel">
              <ListGroup>
                <ListGroup.Item>
                  List item 1
                  <ListGroup.ItemActions>
                    <Button glyph="edit" option="light" />
                  </ListGroup.ItemActions>
                </ListGroup.Item>
                <ListGroup.Item>
                  List item 2
                  <ListGroup.ItemActions>
                    <Button glyph="edit" option="light" />
                  </ListGroup.ItemActions>
                </ListGroup.Item>
                <ListGroup.Item>
                  List item 3
                  <ListGroup.ItemActions>
                    <Button glyph="edit" option="light" />
                  </ListGroup.ItemActions>
                </ListGroup.Item>
                <ListGroup.Item>
                  List item 4
                  <ListGroup.ItemActions>
                    <Button glyph="edit" option="light" />
                  </ListGroup.ItemActions>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className="fd-panel">
              <ProductTile role="button">
                <ProductTile.Media image="https://placeimg.com/640/320/tech" />
                <ProductTile.Content title="Tile Title">
                  <p>Tile Description</p>
                </ProductTile.Content>
              </ProductTile>
            </div>
            <div className="fd-panel">
              <Table
                headers={[
                  'Column Header 1',
                  'Column Header 2',
                  'Column Header 3',
                  'Column Header 4',
                ]}
                tableData={[
                  {
                    rowData: ['Data 1', 'Data 2', 'Data 3', 'Data 4'],
                  },
                  {
                    rowData: ['Data 5', 'Data 6', 'Data 7', 'Data 8'],
                  },
                ]}
              />
            </div>
            <div className="fd-panel">
              <TreeView>
                <TreeView.Head>
                  <TreeView.Col>Column Header 1</TreeView.Col>
                  <TreeView.Col>Column Header 2</TreeView.Col>
                  <TreeView.Col>Column Header 3</TreeView.Col>
                  <TreeView.Col>Column Header 4</TreeView.Col>
                </TreeView.Head>
                <TreeView.Tree>
                  <TreeView.Item>
                    <TreeView.Row>
                      <TreeView.Col>Row 1</TreeView.Col>
                      <TreeView.Col>Data Col 2</TreeView.Col>
                      <TreeView.Col>Data Col 3</TreeView.Col>
                      <TreeView.Col>Data Col 4</TreeView.Col>
                    </TreeView.Row>
                    <TreeView.Branch>
                      <TreeView.Item>
                        <TreeView.Row>
                          <TreeView.Col>Child 1</TreeView.Col>
                          <TreeView.Col>Data Col 2</TreeView.Col>
                          <TreeView.Col>Data Col 3</TreeView.Col>
                          <TreeView.Col>Data Col 4</TreeView.Col>
                        </TreeView.Row>
                        <TreeView.Branch>
                          <TreeView.Item>
                            <TreeView.Row>
                              <TreeView.Col>Grandchild 1</TreeView.Col>
                              <TreeView.Col>Data Col 2</TreeView.Col>
                              <TreeView.Col>Data Col 3</TreeView.Col>
                              <TreeView.Col>Data Col 4</TreeView.Col>
                            </TreeView.Row>
                            <TreeView.Branch>
                              <TreeView.Item>
                                <TreeView.Row>
                                  <TreeView.Col>Great Grandchild 1</TreeView.Col>
                                  <TreeView.Col>Data Col 2</TreeView.Col>
                                  <TreeView.Col>Data Col 3</TreeView.Col>
                                  <TreeView.Col>Data Col 4</TreeView.Col>
                                </TreeView.Row>
                              </TreeView.Item>
                            </TreeView.Branch>
                          </TreeView.Item>
                        </TreeView.Branch>
                      </TreeView.Item>
                      <TreeView.Item>
                        <TreeView.Row>
                          <TreeView.Col>Child 2</TreeView.Col>
                          <TreeView.Col>Data Col 2</TreeView.Col>
                          <TreeView.Col>Data Col 3</TreeView.Col>
                          <TreeView.Col>Data Col 4</TreeView.Col>
                        </TreeView.Row>
                      </TreeView.Item>
                    </TreeView.Branch>
                  </TreeView.Item>
                  <TreeView.Item>
                    <TreeView.Row>
                      <TreeView.Col>Row 2</TreeView.Col>
                      <TreeView.Col>Data Col 2</TreeView.Col>
                      <TreeView.Col>Data Col 3</TreeView.Col>
                      <TreeView.Col>Data Col 4</TreeView.Col>
                    </TreeView.Row>
                    <TreeView.Branch>
                      <TreeView.Item>
                        <TreeView.Row>
                          <TreeView.Col>Child 1</TreeView.Col>
                          <TreeView.Col>Data Col 2</TreeView.Col>
                          <TreeView.Col>Data Col 3</TreeView.Col>
                          <TreeView.Col>Data Col 4</TreeView.Col>
                        </TreeView.Row>
                      </TreeView.Item>
                      <TreeView.Item>
                        <TreeView.Row>
                          <TreeView.Col>Child 2</TreeView.Col>
                          <TreeView.Col>Data Col 2</TreeView.Col>
                          <TreeView.Col>Data Col 3</TreeView.Col>
                          <TreeView.Col>Data Col 4</TreeView.Col>
                        </TreeView.Row>
                      </TreeView.Item>
                    </TreeView.Branch>
                  </TreeView.Item>
                  <TreeView.Item>
                    <TreeView.Row>
                      <TreeView.Col>Row 3</TreeView.Col>
                      <TreeView.Col>Data Col 2</TreeView.Col>
                      <TreeView.Col>Data Col 3</TreeView.Col>
                      <TreeView.Col>Data Col 4</TreeView.Col>
                    </TreeView.Row>
                  </TreeView.Item>
                  <TreeView.Item>
                    <TreeView.Row>
                      <TreeView.Col>Row 4</TreeView.Col>
                      <TreeView.Col>Data Col 2</TreeView.Col>
                      <TreeView.Col>Data Col 3</TreeView.Col>
                      <TreeView.Col>Data Col 4</TreeView.Col>
                    </TreeView.Row>
                    <TreeView.Branch>
                      <TreeView.Item>
                        <TreeView.Row>
                          <TreeView.Col>Child 1</TreeView.Col>
                          <TreeView.Col>Data Col 2</TreeView.Col>
                          <TreeView.Col>Data Col 3</TreeView.Col>
                          <TreeView.Col>Data Col 4</TreeView.Col>
                        </TreeView.Row>
                      </TreeView.Item>
                    </TreeView.Branch>
                  </TreeView.Item>
                </TreeView.Tree>
              </TreeView>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefaultPage);
