import React, { Component } from 'react';
// import { SideNav } from 'fundamental-react';
import { Link } from 'react-router-dom';

export default class SidePanel extends Component {
  static propTypes = {};

  //   <SideNav selectedId="item-sales-4">
  //   <SideNav.List>
  //     <SideNav.ListItem id="item-home" glyph="home">
  //       <Link to="/">Home</Link>
  //     </SideNav.ListItem>
  //   </SideNav.List>
  //   <SideNav.List title="Sales - A/R">
  //     <SideNav.ListItem id="item-sales-1" glyph="sales-order">
  //       <Link to="/ar-invoice/list">Sales Quotation</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-sales-2" glyph="sales-order">
  //       <Link to="/ar-invoice/list">Sales Order</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-sales-3" glyph="sales-order">
  //       <Link to="/ar-invoice/list">Delivery</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-sales-4" glyph="sales-order">
  //       <Link to="/ar-invoice/list">A/R Invoices</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-sales-5" glyph="sales-order">
  //       <Link to="/ar-invoice/list">A/R Credit Memos</Link>
  //     </SideNav.ListItem>
  //   </SideNav.List>
  //   <SideNav.List title="Purchase - A/P">
  //     <SideNav.ListItem id="item-purchase-1" glyph="crm-sales">
  //       <Link to="/ar-invoice/list">Purchase Order</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-purchase-2" glyph="crm-sales">
  //       <Link to="/ar-invoice/list">Goods Return</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-purchase-3" glyph="crm-sales">
  //       <Link to="/ar-invoice/list">A/P Invoices</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-purchase-4" glyph="crm-sales">
  //       <Link to="/ar-invoice/list">A/P Credit Memos</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-purchase-5" glyph="crm-sales">
  //       <Link to="/ar-invoice/list">AP Internal Reconciliation</Link>
  //     </SideNav.ListItem>
  //   </SideNav.List>
  //   <SideNav.List title="FIORI3 COMPONENTS">
  //     <SideNav.ListItem id="item-2-btn" glyph="home">
  //       <Link to="/fiori/forms">Buttons</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-2-form" glyph="home">
  //       <Link to="/fiori/forms">Forms</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-2-table" glyph="home">
  //       <Link to="/fiori/tables">Tables</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-2-tile" glyph="home">
  //       <Link to="/fiori/forms">Tile</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-2-icon" glyph="home">
  //       <Link to="/fiori/forms">Icons</Link>
  //     </SideNav.ListItem>
  //     <SideNav.ListItem id="item-2-notifi" name="Notifications" url="#">
  //       <SideNav.List>
  //         <SideNav.ListItem id="item-2-alert" glyph="home">
  //           <Link to="/fiori/forms">Alert</Link>
  //         </SideNav.ListItem>
  //         <SideNav.ListItem id="item-2-modal" glyph="home">
  //           <Link to="/fiori/forms">Modal</Link>
  //         </SideNav.ListItem>
  //         <SideNav.ListItem id="item-2-popover" glyph="home">
  //           <Link to="/fiori/forms">Popover</Link>
  //         </SideNav.ListItem>
  //       </SideNav.List>
  //     </SideNav.ListItem>
  //   </SideNav.List>
  //   <SideNav.List title="EXAMPLES">
  //     <SideNav.ListItem id="item-1-1" name="Link Item">
  //       <Link to="/examples">Welcome</Link>
  //     </SideNav.ListItem>
  //   </SideNav.List>
  // </SideNav>

  constructor(props) {
    super(props);
    this.handleSaleClick = this.handleSaleClick.bind(this);
    this.handleFinClick = this.handleFinClick.bind(this);
    this.handlePurClick = this.handlePurClick.bind(this);
    this.handleInvtClick = this.handleInvtClick.bind(this);
    this.handleBankClick = this.handleBankClick.bind(this);
    this.handleMasterClick = this.handleMasterClick.bind(this);
    this.handleOverviewClick = this.handleOverviewClick.bind(this);
    this.handleReportClick = this.handleReportClick.bind(this);
    this.handleSettingClick = this.handleSettingClick.bind(this);
    this.handleHomeClick = this.handleHomeClick.bind(this);
  }

  state = {
    finVisible: false,
    saleVisible: false,
    purVisible: false,
    invtVisible: false,
    bankVisible: false,
    masterVisible: false,
    overviewVisible: false,
    reportVisible: false,
    settingVisible: false,
  };

  componentWillMount() {}

  handleHomeClick() {
    this.setState({
      saleVisible: false,
      finVisible: false,
      purVisible: false,
      invtVisible: false,
      bankVisible: false,
      masterVisible: false,
      overviewVisible: false,
      reportVisible: false,
      settingVisible: false,
    });
    this.props.history.push('/');
  }

  handleSaleClick() {
    this.setState({
      saleVisible: !this.state.saleVisible,
      finVisible: false,
      purVisible: false,
      invtVisible: false,
      bankVisible: false,
      masterVisible: false,
      overviewVisible: false,
      reportVisible: false,
      settingVisible: false,
    });
  }

  handleFinClick() {
    this.setState({
      saleVisible: false,
      finVisible: !this.state.finVisible,
      purVisible: false,
      invtVisible: false,
      bankVisible: false,
      masterVisible: false,
      overviewVisible: false,
      reportVisible: false,
      settingVisible: false,
    });
  }

  handlePurClick() {
    this.setState({
      saleVisible: false,
      finVisible: false,
      purVisible: !this.state.purVisible,
      invtVisible: false,
      bankVisible: false,
      masterVisible: false,
      overviewVisible: false,
      reportVisible: false,
      settingVisible: false,
    });
  }

  handleInvtClick() {
    this.setState({
      saleVisible: false,
      finVisible: false,
      purVisible: false,
      invtVisible: !this.state.invtVisible,
      bankVisible: false,
      masterVisible: false,
      overviewVisible: false,
      reportVisible: false,
      settingVisible: false,
    });
  }

  handleBankClick() {
    this.setState({
      saleVisible: false,
      finVisible: false,
      purVisible: false,
      invtVisible: false,
      bankVisible: !this.state.bankVisible,
      masterVisible: false,
      overviewVisible: false,
      reportVisible: false,
      settingVisible: false,
    });
  }

  handleMasterClick() {
    this.setState({
      saleVisible: false,
      finVisible: false,
      purVisible: false,
      invtVisible: false,
      bankVisible: false,
      masterVisible: !this.state.masterVisible,
      overviewVisible: false,
      reportVisible: false,
      settingVisible: false,
    });
  }

  handleOverviewClick() {
    this.setState({
      saleVisible: false,
      finVisible: false,
      purVisible: false,
      invtVisible: false,
      bankVisible: false,
      masterVisible: false,
      overviewVisible: !this.state.overviewVisible,
      reportVisible: false,
      settingVisible: false,
    });
  }

  handleReportClick() {
    this.setState({
      saleVisible: false,
      finVisible: false,
      purVisible: false,
      invtVisible: false,
      bankVisible: false,
      masterVisible: false,
      overviewVisible: false,
      reportVisible: !this.state.reportVisible,
      settingVisible: false,
    });
  }

  handleSettingClick() {
    this.setState({
      saleVisible: false,
      finVisible: false,
      purVisible: false,
      invtVisible: false,
      bankVisible: false,
      masterVisible: false,
      overviewVisible: false,
      reportVisible: false,
      settingVisible: !this.state.settingVisible,
    });
  }

  render() {
    return (
      <nav className="fd-side-nav">
        <ul className="fd-side-nav__list">
          <li className="fd-side-nav__item">
            <a className="fd-side-nav__link" href="javascript:;" onClick={this.handleHomeClick}>
              <span className="fd-side-nav__icon sap-icon--home sap-icon--l" role="presentation" />
              Homepage
            </a>
          </li>
          <li className="fd-side-nav__item">
            <a
              className="fd-side-nav__link has-child"
              href="javascript:;"
              aria-controls="nav-fin"
              aria-expanded={this.state.finVisible}
              aria-haspopup={this.state.finVisible}
              onClick={this.handleFinClick}
            >
              <span
                className="fd-side-nav__icon sap-icon--customer-financial-fact-sheet sap-icon--l"
                role="presentation"
              />
              Financials
            </a>
            {/* Chart of Accounts
Account Group
Journal Entries
Exchange Rate Difference
Period-End Processing
Financial Report Templates
Posting Templates
Recurring Posting */}
            <ul className="fd-side-nav__sublist" id="Rk65C501" aria-hidden={!this.state.finVisible}>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Chart of Accounts
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Account Group
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Journal Entries
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Period-End Processing
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Exchange Rate Difference
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Financial Report Templates
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Posting Templates
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Recurring Posting
                </a>
              </li>
            </ul>
          </li>
          <li className="fd-side-nav__item">
            <a
              className="fd-side-nav__link has-child"
              href="javascript:;"
              aria-controls="mM3Zf660"
              aria-haspopup="true"
              aria-expanded={this.state.saleVisible}
              onClick={this.handleSaleClick}
            >
              <span className="fd-side-nav__icon sap-icon--leads sap-icon--l" role="presentation" />
              Sales - A/R
            </a>
            {/* Sales Quotation
Sales Order
Delivery
Return
A/R Invoices
A/R Credit Memos
AR Internal Reconciliation
Recurring Transactions
Dunning */}
            <ul
              className="fd-side-nav__sublist"
              id="mM3Zf660"
              aria-hidden={!this.state.saleVisible}
            >
              <li className="fd-side-nav__subitem">
                <Link className="fd-side-nav__sublink" to="/ar-invoice/list">
                  Sales Quotation
                </Link>
              </li>
              <li className="fd-side-nav__subitem">
                <Link className="fd-side-nav__sublink" to="/ar-invoice/list">
                  Sales Order
                </Link>
              </li>
              <li className="fd-side-nav__subitem">
                <Link className="fd-side-nav__sublink" to="/ar-invoice/list">
                  Delivery
                </Link>
              </li>
              <li className="fd-side-nav__subitem">
                <Link className="fd-side-nav__sublink" to="/ar-invoice/list">
                  Return
                </Link>
              </li>
              <li className="fd-side-nav__subitem">
                <Link className="fd-side-nav__sublink is-selected" to="/ar-invoice/list">
                  A/R Invoices
                </Link>
              </li>
              <li className="fd-side-nav__subitem">
                <Link className="fd-side-nav__sublink" to="/ar-invoice/list">
                  A/R Credit Memos
                </Link>
              </li>
              <li className="fd-side-nav__subitem">
                <Link className="fd-side-nav__sublink" to="/ar-invoice/list">
                  AR Internal Reconciliation
                </Link>
              </li>
              <li className="fd-side-nav__subitem">
                <Link className="fd-side-nav__sublink" to="/ar-invoice/list">
                  Recurring Transactions
                </Link>
              </li>
              <li className="fd-side-nav__subitem">
                <Link className="fd-side-nav__sublink" to="/ar-invoice/list">
                  Dunning
                </Link>
              </li>
            </ul>
          </li>
          <li className="fd-side-nav__item">
            <a
              className="fd-side-nav__link has-child"
              href="javascript:;"
              aria-controls="Rk65C501"
              aria-haspopup="true"
              aria-expanded={this.state.purVisible}
              onClick={this.handlePurClick}
            >
              <span
                className="fd-side-nav__icon sap-icon--crm-sales sap-icon--l"
                role="presentation"
              />
              Purchase - A/P
            </a>
            {/* Purchase Order
Goods Receipts PO
Goods Return
A/P Invoices
A/P Credit Memos
AP Internal Reconciliation
Landed Cost
Recurring Transactions */}
            <ul className="fd-side-nav__sublist" id="Rk65C501" aria-hidden={!this.state.purVisible}>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Purchase Order
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Goods Receipts PO
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Goods Return
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  A/P Invoices
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  A/P Credit Memos
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  AP Internal Reconciliation
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Landed Cost
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Recurring Transactions
                </a>
              </li>
            </ul>
          </li>
          <li className="fd-side-nav__item">
            <a
              className="fd-side-nav__link has-child"
              href="javascript:;"
              aria-controls="Rk65C501"
              aria-haspopup="true"
              aria-expanded={this.state.invtVisible}
              onClick={this.handleInvtClick}
            >
              <span
                className="fd-side-nav__icon sap-icon--inventory sap-icon--l"
                role="presentation"
              />
              Inventory
            </a>
            {/* Goods Receipt
Goods Issue
Inventory Transfer
Inventory Counting
Inventory Opening Balance
Inventory Revaluation
Price Lists
Bin Locations */}
            <ul
              className="fd-side-nav__sublist"
              id="Rk65C501"
              aria-hidden={!this.state.invtVisible}
            >
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Goods Receipt
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Goods Issue
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Inventory Transfer
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Inventory Counting
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Inventory Opening Balance
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Inventory Revaluation
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Price Lists
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Bin Locations
                </a>
              </li>
            </ul>
          </li>
          <li className="fd-side-nav__item">
            <a
              className="fd-side-nav__link has-child"
              href="javascript:;"
              aria-controls="Rk65C501"
              aria-haspopup="true"
              aria-expanded={this.state.bankVisible}
              onClick={this.handleBankClick}
            >
              <span className="fd-side-nav__icon sap-icon--lead sap-icon--l" role="presentation" />
              Banking
            </a>
            {/* Incoming Payments
Outgoing Payments
Bank Account Manager
Bank Statements
Bank Reconciliation */}
            <ul
              className="fd-side-nav__sublist"
              id="Rk65C501"
              aria-hidden={!this.state.bankVisible}
            >
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Incoming Payments
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Outgoing Payments
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Bank Account Manager
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Bank Statements
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Bank Reconciliation
                </a>
              </li>
            </ul>
          </li>
          <li className="fd-side-nav__item">
            <a
              className="fd-side-nav__link has-child"
              href="javascript:;"
              aria-controls="Rk65C501"
              aria-haspopup="true"
              aria-expanded={this.state.masterVisible}
              onClick={this.handleMasterClick}
            >
              <span
                className="fd-side-nav__icon sap-icon--database sap-icon--l"
                role="presentation"
              />
              Master Data
            </a>
            {/* Customers
Vendors
Products
Blanket Agreement */}
            <ul
              className="fd-side-nav__sublist"
              id="Rk65C501"
              aria-hidden={!this.state.masterVisible}
            >
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Customers
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Vendors
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Products
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Blanket Agreement
                </a>
              </li>
            </ul>
          </li>
          <li className="fd-side-nav__item">
            <a
              className="fd-side-nav__link has-child"
              href="javascript:;"
              aria-controls="Rk65C501"
              aria-haspopup="true"
              aria-expanded={this.state.overviewVisible}
              onClick={this.handleOverviewClick}
            >
              <span
                className="fd-side-nav__icon sap-icon--overview-chart sap-icon--l"
                role="presentation"
              />
              Smart Overview
            </a>
            {/* Cash Management Overview
Financial Info Overview
Accounts Receivable Overview
Accounts Payable Overview */}
            <ul
              className="fd-side-nav__sublist"
              id="Rk65C501"
              aria-hidden={!this.state.overviewVisible}
            >
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Cash Management Overview
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Financial Info Overview
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Accounts Receivable Overview
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Accounts Payable Overview
                </a>
              </li>
            </ul>
          </li>
          <li className="fd-side-nav__item">
            <a
              className="fd-side-nav__link has-child"
              href="javascript:;"
              aria-controls="Rk65C501"
              aria-haspopup="true"
              aria-expanded={this.state.reportVisible}
              onClick={this.handleReportClick}
            >
              <span
                className="fd-side-nav__icon sap-icon--opportunity sap-icon--l"
                role="presentation"
              />
              Reports
            </a>
            {/* Balance Sheet
Profit and Loss Statement
Trial Balance Report
Aging Report
General Ledger Report
Statement of Cash Flows
Inventory Transaction Report
Inventory Audit Report
Inventory Valuation Report
Inventory Counting Transaction Report */}
            <ul
              className="fd-side-nav__sublist"
              id="Rk65C501"
              aria-hidden={!this.state.reportVisible}
            >
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Balance Sheet
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Profit and Loss Statement
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Trial Balance Report
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Aging Report
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  General Ledger Report
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Statement of Cash Flows
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Inventory Transaction Report
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Inventory Audit Report
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Inventory Valuation Report
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Inventory Counting Transaction Report
                </a>
              </li>
            </ul>
          </li>
          <li className="fd-side-nav__item">
            <a
              className="fd-side-nav__link has-child"
              href="javascript:;"
              aria-controls="nav-settings"
              aria-expanded={this.state.settingVisible}
              aria-haspopup={this.state.settingVisible}
              onClick={this.handleSettingClick}
            >
              <span
                className="fd-side-nav__icon sap-icon--action-settings sap-icon--l"
                role="presentation"
              />
              Settings
            </a>
            {/* General Settings
Accounting
Inventory
Payment Terms
Payment Method
Document Settings
Printing Templates
Users & Roles */}
            <ul
              className="fd-side-nav__sublist"
              id="nav-settings"
              aria-hidden={!this.state.settingVisible}
            >
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  General Settings
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Accounting
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Payment Terms
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Payment Method
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Document Settings
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Printing Templates
                </a>
              </li>
              <li className="fd-side-nav__subitem">
                <a className="fd-side-nav__sublink" href="javascript:;">
                  Users & Roles
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    );
  }
}
