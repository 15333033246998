// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html
import { lazy } from 'react';
// import { Layout } from '../common';
// import { ListView, DetailView, EditView } from './';
const ListView = lazy(() => import('./ListView'));
const DetailView = lazy(() => import('./DetailView'));
const EditView = lazy(() => import('./EditView'));

export default {
  path: 'ar-invoice',
  // component: Layout,
  childRoutes: [
    { path: 'list', name: 'List View', component: ListView },
    { path: 'view/:id', name: 'Detail View', component: DetailView },
    { path: 'edit/:id', name: 'Edit View', component: EditView },
  ],
};
