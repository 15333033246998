import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import {
  Panel,
  FormSet,
  FormItem,
  FormLabel,
  FormInput,
  FormTextarea,
  FormMessage,
  FormSelect,
  FormFieldset,
  FormLegend,
  FormRadioGroup,
  FormRadioItem,
} from 'fundamental-react';
export class FormsPage extends Component {
  static propTypes = {
    fiori: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="fiori-forms-page fd-has-padding-tiny">
        <Panel>
          <Panel.Header>
            <Panel.Head description="" title="Inputs" />
            <Panel.Actions />
          </Panel.Header>
          <Panel.Body>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="input-1">Default Input</FormLabel>
                <FormInput id="input-1" placeholder="Field placeholder text" type="text" />
              </FormItem>
            </FormSet>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="input-2" required>
                  Required Input
                </FormLabel>
                <FormInput id="input-2" placeholder="Field placeholder text" type="text" />
              </FormItem>
            </FormSet>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="input-3" required>
                  Password
                </FormLabel>
                <FormInput id="input-3" placeholder="Field placeholder text" type="password" />
              </FormItem>
            </FormSet>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="textarea-1" required>
                  Text area
                </FormLabel>
                <FormTextarea
                  defaultValue=" Pellentesque metus lacus commodo eget justo ut rutrum varius nunc."
                  id="textarea-1"
                />
              </FormItem>
            </FormSet>
          </Panel.Body>
          <Panel.Footer />
        </Panel>
        <Panel>
          <Panel.Header>
            <Panel.Head description="" title="Input Help Elements" />
            <Panel.Actions />
          </Panel.Header>
          <Panel.Body>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="input-4">
                  Input with inline help
                  <span className="fd-inline-help fd-has-float-right">
                    <span className="fd-inline-help__content fd-inline-help__content--bottom-right">
                      Lorem ipsum dolor sit amet, consectetur adipiscing.
                    </span>
                  </span>
                </FormLabel>
                <FormInput id="input-4" type="text" />
              </FormItem>
            </FormSet>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="input-5">Input with Help Message</FormLabel>
                <FormInput id="input-5" type="text" />
                <FormMessage type="help">
                  Pellentesque metus lacus commodo eget justo ut rutrum varius nunc
                </FormMessage>
              </FormItem>
            </FormSet>
          </Panel.Body>
          <Panel.Footer />
        </Panel>
        <Panel>
          <Panel.Header>
            <Panel.Head description="" title="Input States" />
            <Panel.Actions />
          </Panel.Header>
          <Panel.Body>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="OatmD552">Normal Input</FormLabel>
                <FormInput id="OatmD552" placeholder="Field placeholder text" type="text" />
                <FormMessage>
                  Pellentesque metus lacus commodo eget justo ut rutrum varius nunc
                </FormMessage>
              </FormItem>
            </FormSet>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="OatmD553">Valid Input</FormLabel>
                <FormInput
                  id="OatmD553"
                  placeholder="Field placeholder text"
                  state="valid"
                  type="text"
                />
              </FormItem>
            </FormSet>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="OatmD554">Invalid Input</FormLabel>
                <FormInput
                  id="OatmD554"
                  placeholder="Field placeholder text"
                  state="invalid"
                  type="text"
                />
                <FormMessage type="error">
                  Pellentesque metus lacus commodo eget justo ut rutrum varius nunc
                </FormMessage>
              </FormItem>
            </FormSet>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="OatmD555">Warning Input</FormLabel>
                <FormInput
                  id="OatmD555"
                  placeholder="Field placeholder text"
                  state="warning"
                  type="text"
                />
                <FormMessage type="warning">
                  Pellentesque metus lacus commodo eget justo ut rutrum varius nunc
                </FormMessage>
              </FormItem>
            </FormSet>
          </Panel.Body>
          <Panel.Footer />
        </Panel>
        <Panel>
          <Panel.Header>
            <Panel.Head description="" title="Disabled and Read Only" />
            <Panel.Actions />
          </Panel.Header>
          <Panel.Body>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="OatmD557">Disabled Input</FormLabel>
                <FormInput
                  disabled
                  id="OatmD557"
                  placeholder="Field placeholder text"
                  type="text"
                />
              </FormItem>
            </FormSet>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="OatmD558">Readonly Input</FormLabel>
                <FormInput
                  id="OatmD558"
                  placeholder="Field placeholder text"
                  readOnly
                  type="text"
                />
              </FormItem>
            </FormSet>
          </Panel.Body>
        </Panel>
        <Panel>
          <Panel.Header>
            <Panel.Head description="" title="Selects" />
            <Panel.Actions />
          </Panel.Header>
          <Panel.Body>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="select-1">Default Select</FormLabel>
                <FormSelect id="select-1">
                  <option value="1">Duis malesuada odio volutpat elementum</option>
                  <option value="2">Suspendisse ante ligula</option>
                  <option value="3">Sed bibendum sapien at posuere interdum</option>
                </FormSelect>
              </FormItem>
            </FormSet>
            <FormSet>
              <FormItem>
                <FormLabel htmlFor="select-1">Default Select</FormLabel>
                <FormSelect disabled id="select-1">
                  <option value="1">Duis malesuada odio volutpat elementum</option>
                  <option value="2">Suspendisse ante ligula</option>
                  <option value="3">Sed bibendum sapien at posuere interdum</option>
                </FormSelect>
              </FormItem>
            </FormSet>
          </Panel.Body>
        </Panel>
        <Panel>
          <Panel.Header>
            <Panel.Head description="" title="Radio Buttons and Checkboxs" />
            <Panel.Actions />
          </Panel.Header>
          <Panel.Body>
            <FormFieldset>
              <FormLegend>Radio buttons</FormLegend>
              <FormRadioGroup>
                <FormRadioItem id="radio-1">Option 1</FormRadioItem>
                <FormRadioItem id="radio-2">Option 2</FormRadioItem>
                <FormRadioItem defaultChecked id="radio-3">
                  Option 3
                </FormRadioItem>
              </FormRadioGroup>
            </FormFieldset>
            <FormFieldset>
              <FormLegend>Radio buttons disabled</FormLegend>
              <FormRadioGroup>
                <FormRadioItem defaultChecked disabled id="radio-4">
                  Option 1
                </FormRadioItem>
                <FormRadioItem disabled id="radio-5">
                  Option 2
                </FormRadioItem>
                <FormRadioItem disabled id="radio-6">
                  Option 3
                </FormRadioItem>
              </FormRadioGroup>
            </FormFieldset>
            <FormFieldset>
              <FormLegend>Inline Radio buttons</FormLegend>
              <FormRadioGroup inline>
                <FormRadioItem id="radio-7">Option 1</FormRadioItem>
                <FormRadioItem defaultChecked id="radio-8">
                  Option 2
                </FormRadioItem>
                <FormRadioItem id="radio-9">Option 3</FormRadioItem>
              </FormRadioGroup>
            </FormFieldset>
            <FormFieldset>
              <FormLegend>Checkboxes</FormLegend>
              <FormItem isCheck>
                <FormInput id="checkbox-1" name="checkbox-name-1" type="checkbox" value="" />
                <FormLabel htmlFor="checkbox-1">Option One</FormLabel>
              </FormItem>
              <FormItem isCheck>
                <FormInput id="checkbox-2" name="checkbox-name-2" type="checkbox" value="" />
                <FormLabel htmlFor="checkbox-2">Option Two</FormLabel>
              </FormItem>
              <FormItem isCheck>
                <FormInput id="checkbox-3" name="checkbox-name-3" type="checkbox" value="" />
                <FormLabel htmlFor="checkbox-3">Option Three</FormLabel>
              </FormItem>
            </FormFieldset>
            <FormFieldset>
              <FormLegend>Inline Checkbox buttons</FormLegend>
              <FormItem isCheck isInline>
                <FormLabel htmlFor="checkbox-4">
                  <FormInput id="checkbox-4" name="checkbox-name-4" type="checkbox" value="" />
                  Option One
                </FormLabel>
              </FormItem>
              <FormItem isCheck isInline>
                <FormLabel htmlFor="checkbox-5">
                  <FormInput id="checkbox-5" name="checkbox-name-5" type="checkbox" value="" />
                  Option Two
                </FormLabel>
              </FormItem>
              <FormItem isCheck isInline>
                <FormLabel htmlFor="checkbox-6">
                  <FormInput id="checkbox-6" name="checkbox-name-6" type="checkbox" value="" />
                  Option Three
                </FormLabel>
              </FormItem>
            </FormFieldset>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    fiori: state.fiori,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormsPage);
