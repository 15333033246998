import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '../common';

const Header = React.lazy(() => import('../common/Header'));
const SidePanel = React.lazy(() => import('../common/SidePanel'));

/*
  This is the root component of your app. Here you define the overall layout
  and the container of the react router.
  You should adjust it according to the requirement of your app.
*/
export default class App extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: '',
  };

  render() {
    return (
      <div className="fd-shell fd-shell--fundamentals">
        <div className="fd-shell__header">
          <Suspense fallback={<Spinner />}>
            <Header history={this.props.history} />
          </Suspense>
        </div>
        <div className="fd-shell__app">
          <div className="fd-app">
            <div className="fd-app__navigation fd-app__navigation--vertical">
              <Suspense fallback={<Spinner />}>
                <SidePanel history={this.props.history} />
              </Suspense>
            </div>
            <main className="fd-app__main">{this.props.children}</main>
          </div>
        </div>
        {/* <div className="fd-shell__footer"><span>SAP</span></div> */}
      </div>
    );
  }
}
